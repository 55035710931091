import { Button, Flex, Icon, Stack, Checkbox } from '@gr4vy/poutine-react'
import { Size8 } from '@gr4vy/poutine-tokens'
import { Form, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { isValidElement, ReactNode, useEffect, useState } from 'react'

type CredentialFieldProps = {
  item: any
  required: boolean
  requiredMessage?: string
  secret: boolean
  disabled?: boolean
  group?: string
  onEdit?: (isEditing: boolean) => void
  extra?: ReactNode
  hasShowHideToggle?: boolean
  placeholder?: string
}

const extractDisplayName = (item: any) =>
  isValidElement(item.displayName)
    ? item.displayName.props.children
    : item.displayName

export const CredentialField = ({
  item,
  required,
  requiredMessage = 'Please provide a value for this field',
  secret,
  disabled,
  group,
  onEdit,
  extra,
  hasShowHideToggle,
  placeholder = '',
}: CredentialFieldProps) => {
  const form = Form.useFormInstance()
  const [isEditing, setEditing] = useState(false)
  const [isShown, setShown] = useState(false)
  const fieldName = group ? [group, item.key] : [item.key]

  useEffect(() => {
    setEditing(false)
  }, [secret])

  const toggleEditing = () => {
    if (isEditing) {
      const values = form.getFieldsValue()
      form.setFieldsValue({
        ...values,
        ...(group
          ? { [group]: { ...values[group], [item.key]: undefined } }
          : { [item.key]: undefined }),
      })
      // clear validation error
      form.validateFields([item.key])
    }
    setEditing(!isEditing)
    onEdit?.(!isEditing)
  }

  const Toggle = () => (
    <Button
      variant="tertiary"
      size="small"
      onClick={toggleEditing}
      disabled={disabled}
    >
      {isEditing ? (
        <Icon name="close-md" size="small" />
      ) : (
        <Icon name="edit-pencil-sm" size="small" />
      )}
    </Button>
  )

  const toggleShowHide = () => setShown(!isShown)

  const ShowHideToggle = () => (
    <Button
      variant="tertiary"
      size="small"
      onClick={toggleShowHide}
      style={{ marginRight: `-${Size8}` }}
    >
      <Icon name={isShown ? 'hide' : 'show'} color="gray60" />
    </Button>
  )

  const isMasked = secret && !isEditing && !hasShowHideToggle
  placeholder = isMasked ? '********' : placeholder

  const onReset = () => {
    if (!secret) {
      return
    }
    form.setFieldValue(fieldName, undefined)
    setEditing(false)
  }

  return (
    <Form.Item
      name={fieldName}
      required={required}
      rules={[
        {
          required: required && !isMasked,
          message: requiredMessage,
        },
      ]}
      label={item.displayName}
      key={item.key}
      style={{ marginBottom: 0 }}
      onReset={onReset}
      extra={extra}
    >
      {item.format === 'multiline' ? (
        <Stack gap={8} aria-label={extractDisplayName(item)}>
          <TextArea
            required={required}
            rows={3}
            placeholder={placeholder}
            disabled={isMasked || disabled}
          />
          {secret && (
            <Flex justifyContent="flex-end">
              <Button
                onClick={toggleEditing}
                disabled={disabled}
                variant="tertiary"
              >
                {isEditing ? (
                  <Icon name="close-md" />
                ) : (
                  <Icon name="edit-pencil-md" />
                )}
                {isEditing ? 'Cancel' : 'Edit'}
              </Button>
            </Flex>
          )}
        </Stack>
      ) : item.format === 'text' ? (
        <Input
          placeholder={placeholder}
          disabled={isMasked || disabled}
          addonAfter={secret && <Toggle />}
        />
      ) : item.format == 'boolean' ? (
        <Checkbox
          disabled={isMasked || disabled}
          onCheckedChange={(checked) =>
            form.setFieldValue(fieldName, `${checked}`)
          }
          defaultChecked={form.getFieldValue(fieldName) === 'true'}
        />
      ) : (
        <Input
          type={hasShowHideToggle && !isShown ? 'password' : 'text'}
          placeholder={placeholder}
          disabled={(!hasShowHideToggle && isMasked) || disabled}
          addonAfter={!hasShowHideToggle && secret && <Toggle />}
          suffix={hasShowHideToggle ? <ShowHideToggle /> : <span />}
        />
      )}
    </Form.Item>
  )
}

export default CredentialField
