export enum Resource {
  all = '*',
  antiFraudServiceDefinitions = 'anti-fraud-service-definitions',
  antiFraudServices = 'anti-fraud-services',
  apiKeyPairs = 'api-key-pairs',
  auditLogs = 'audit-logs',
  binChecker = 'bin-checker',
  buyers = 'buyers',
  buyersBillingDetails = 'buyers.billing-details',
  checkoutSessions = 'checkout-sessions',
  connections = 'connections',
  digitalWallets = 'digital-wallets',
  flows = 'flows',
  giftCardServiceDefinitions = 'gift-card-service-definitions',
  giftCardServices = 'gift-card-services',
  healthDashboard = 'health-dashboard',
  me = 'users.me',
  merchantAccounts = 'merchant-accounts',
  paymentMethods = 'payment-methods',
  paymentMethodDefinitions = 'payment-method-definitions',
  paymentOptions = 'payment-options',
  paymentServiceDefinitions = 'payment-service-definitions',
  paymentServices = 'payment-services',
  reports = 'reports',
  sessions = 'sessions',
  transactions = 'transactions',
  users = 'users',
  dashboard = 'dashboard',
  roles = 'roles',
  vaultForwardAuthentications = 'vault-forward-authentications',
  webhookSubscriptions = 'webhook-subscriptions',
}
